import React from "react"

import pic01 from "../assets/images/gallery/cely_rose_bebe_1.jpg"
import pic02 from "../assets/images/gallery/cely_rose_bebe_2.jpg"
import pic03 from "../assets/images/gallery/cely_rose_bebe_3.jpg"
import pic04 from "../assets/images/gallery/cely_rose_grossesse.jpg"
import pic05 from "../assets/images/gallery/cely_rose_grossesse_1.jpg"
import pic06 from "../assets/images/gallery/cely_rose_grossesse_2.jpg"
import pic07 from "../assets/images/gallery/cely_rose_grossesse_3.jpg"
import pic08 from "../assets/images/gallery/cely_rose_grossesse_4.jpg"
import pic09 from "../assets/images/gallery/cely_rose_grossesse_5.jpg"
import pic10 from "../assets/images/gallery/cely_rose_sombre.jpg"
import noel_bebe from "../assets/images/gallery/cely_rose_noel_bebe.jpg"

export default () => (
    <div className="grid-wrapper">
    <div className="col-12">
        <header className="major">
            <h2>PORTFOLIO</h2>
        </header>
    </div>
    <div className="col-12">
        <span className="image fit"><img src={noel_bebe} alt=""/></span>
    </div>
    <div className="col-6">
        <span className="image fit"><img src={pic01} alt=""/></span>
    </div>
    <div className="col-6">
        <span className="image fit"><img src={pic02} alt=""/></span>
    </div>
    <div className="col-12">
        <span className="image fit"><img src={pic03} alt=""/></span>
    </div>
    <div className="col-6">
        <span className="image fit"><img src={pic05} alt=""/></span>
    </div>
    <div className="col-6">
        <span className="image fit"><img src={pic06} alt=""/></span>
    </div>
    <div className="col-12">
        <span className="image fit"><img src={pic04} alt=""/></span>
    </div>
    <div className="col-12">
        <span className="image fit"><img src={pic07} alt=""/></span>
    </div>
    <div className="col-6">
        <span className="image fit"><img src={pic09} alt=""/></span>
    </div>
    <div className="col-6">
        <span className="image fit"><img src={pic08} alt=""/></span>
    </div>
    <div className="col-12">
        <span className="image fit"><img src={pic10} alt=""/></span>
    </div>
</div>
)