import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';
import ContactForm from "../components/contactForm"
import Portfolio from "../components/portfolio"
import tarifs from "../assets/images/tarifs.jpg"

class Homepage extends React.Component {   
    render() {
        const siteTitle = "Cely Rose Photography";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="portfolio" className="main style1 special">
                    <Portfolio/>
                </section>
                <section id="contact" className="main style2 special">
                    <div className="inner">                    
                        <h1>CONTACT</h1>               
                        <div className="contact">
                            <ContactForm/>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;