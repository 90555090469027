import React from "react"

export default () => (
<form name="contact" method="POST" action="/success" data-netlify-honeypot="bot-field" data-netlify="true">
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />   

    <div className="field">
        <label htmlFor="name">Nom</label>
        <input type="text" name="name" id="name" placeholder="Nom" required/>
    </div>

    <div className="field">
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" placeholder="contact@site.com" required/>
    </div>

    <div className="field">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="6" placeholder="Votre message" required/>
    </div>

    <button type="submit" className="special">Envoyer</button>
    <input type="reset" value="Supprimer" />
</form>
)